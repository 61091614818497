import { render, staticRenderFns } from "./StudentNoticeTemplate.vue?vue&type=template&id=792c00bc&scoped=true"
import script from "./StudentNoticeTemplate.vue?vue&type=script&lang=js"
export * from "./StudentNoticeTemplate.vue?vue&type=script&lang=js"
import style0 from "./StudentNoticeTemplate.vue?vue&type=style&index=0&id=792c00bc&prod&lang=stylus&scoped=true"
import style1 from "./StudentNoticeTemplate.vue?vue&type=style&index=1&id=792c00bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792c00bc",
  null
  
)

export default component.exports