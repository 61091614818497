<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label='模板名称：'>
                    <el-input v-model='filters.name' placeholder='请输入' clearable @clear='handleClear'></el-input>
                </el-form-item>
                <el-form-item label='类型：'>
                    <el-select v-model="filters.noticeType" placeholder="请选择" clearable>
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格主体 -->
        <el-table
        highlight-current-row
        :data="tableData"
        border
        :header-cell-style="headClass"
        :cell-style="rowClass" 
        v-loading='listLoading'
        @current-change='currentChange'
        style="width: 95%">
            <el-table-column type='index' label="序号" width="60"></el-table-column>
            <el-table-column prop='Name' label='模板名称' min-width="200"></el-table-column>
            <el-table-column prop='NoticeType' label='通知类型' min-width="100">
                <template slot-scope='scope'>
                    <span v-if='scope.row.NoticeType == 0'>注册</span>
                    <span v-if='scope.row.NoticeType == 1'>报名成功</span>
                    <span v-if='scope.row.NoticeType == 2'>考试</span>
                    <span v-if='scope.row.NoticeType == 3'>发证</span>
                    <span v-if='scope.row.NoticeType == 4'>学习</span>
                    <span v-if='scope.row.NoticeType == 5'>通知</span>
                </template>
            </el-table-column>
            <el-table-column prop='Content' label='通知内容' show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column prop='IsEnable' label='启用标识' min-width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsEnable' type='success'>启用</el-tag>
                    <el-tag v-else type='warning'>禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='CreateTime' :formatter="formatCreateTime" label='创建时间' min-width="100"></el-table-column>
            <el-table-column prop='CreateUserName' label='创建人'></el-table-column>
            <el-table-column prop='Remarks' label='备注' show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pages.PageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="this.pages.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.pages.DataCount">
        </el-pagination>
        <!-- 新增/编辑 -->
        <el-dialog
        :close-on-click-modal="false"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        @close='handleClose'
        width="50%">
        <el-form ref="formRef" :model="form" :rules='formRules' label-width='150px'>
            <el-form-item label="模板名称：" prop='Name' >
                <el-input v-model="form.Name"  @input='change'></el-input>
            </el-form-item>
            <el-form-item label="类型：" prop='NoticeType'>
                <el-select v-model="form.NoticeType" placeholder="请选择" @change='updateSelect'>
                    <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='启用标识：' prop='IsEnable'>
                <el-select v-model="form.IsEnable" placeholder="请选择" @change='updateSelect'>
                    <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="通知内容：" prop='Content'>
                <el-input v-model="form.Content" type='textarea' style="height:150px;" placeholder='请输入内容' @input='change'></el-input>
            </el-form-item>
            <el-form-item label='备注：' prop='Remarks'>
                <el-input v-model='form.Remarks' type='textarea' placeholder='请输入内容' @input='change'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogSubmit">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs'
import { getStudentNoticeTemplateListPage,addStudentNoticeTemplate,updateStudentNoticeTemplate,deleteStudentNoticeTemplate } from "@/api/api"
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList:[],
            tableData:[],
            listLoading:false,
            pages:{
                DataCount:0,
                PageSize:20,
                PageIndex:1
            },
            filters:{
                name:'',
                noticeType:null
            },
            options:[
                {label:'注册',value:0},
                {label:'报名成功',value:1},
                {label:'考试',value:2},
                {label:'发证',value:3},
                {label:'学习',value:4},
                {label:'通知',value:5}
            ],
            options1:[
                {label:'注册',value:0},
                {label:'报名成功',value:1},
                {label:'考试',value:2},
                {label:'发证',value:3},
                {label:'学习',value:4},
                {label:'通知',value:5}
            ],
            options2:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],   
            currentRow:null,
            // 对话框
            dialogTitle:'',
            dialogVisible:false,
            form:{
                addOrEdit:true,
                Name:'',
                NoticeType:'',
                Content:'',
                Remarks:'',
                IsEnable:true
            },
            formRules:{
                Name:[{required:true,message:'请输入模板名称',trigger:'blur'}],
                NoticeType:[{required:true,message:'请选择类型',trigger:'change'}],
                Content:[{required:true,message:'请输入通知内容',trigger:'blur'}],
                IsEnable:[{required:true,message:'请选择启用/禁用',trigger:'change'}]
            }
        }
    },
    methods:{
        // 筛选框清空重新获取数据
        handleClear(){
            this.getData()
        },
        // 对话框关闭处理
        handleClose(){
            this.$refs.formRef.resetFields()
            this.form = {}
        },
        // 新增/编辑表单提交
        dialogSubmit(){
            if(this.form.addOrEdit){
                this.$refs.formRef.validate((valid) => {
                    if(valid){
                        var params = {
                            Name:this.form.Name,
                            NoticeType:this.form.NoticeType,
                            Content:this.form.Content,
                            IsEnable:this.form.IsEnable,
                            Remarks:this.form.Remarks
                        }
                        addStudentNoticeTemplate(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type: 'success'
                                })
                                this.dialogVisible = false
                                this.getData()
                            }else{
                                this.dialogVisible = false
                                this.$message.error(res.data.Message);
                            }
                        }).catch(() => {})
                    }else{return}
                })
            }else{
                this.$refs.formRef.validate((valid) => {
                    if(valid){
                        var params = {
                            ID:this.currentRow.ID,
                            Name:this.form.Name,
                            NoticeType:this.form.NoticeType,
                            Content:this.form.Content,
                            IsEnable:this.form.IsEnable,
                            Remarks:this.form.Remarks
                        }
                        updateStudentNoticeTemplate(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type: 'success'
                                })
                                this.dialogVisible = false
                                this.getData()

                            }else{
                                this.dialogVisible = false
                                this.$message.error(res.data.Message)
                            }
                        }).catch(() => {})
                    }else{return}
                })
                
            }
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value        
            this.getData()
        },
        handleCurrentChange(value){
            // if(this.filters.name != '' || this.filters.noticeType != null){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.PageIndex = value
            // }              
            this.getData()
        },
        // 获取当行数据
        currentChange(row){
            this.currentRow = row
        },
        // 查询
        getStudentNoticeTemplateListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.form.addOrEdit = true
            this.form.IsEnable = true
            this.dialogTitle = '新增'
            this.dialogVisible = true
        },
        // 编辑
        handleEdit(){
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.dialogVisible = false
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.form.addOrEdit = false
                this.form.Name = row.Name
                this.form.NoticeType = row.NoticeType
                this.form.Content = row.Content
                this.form.IsEnable = row.IsEnable
                this.form.Remarks = row.Remarks
                this.dialogTitle = '编辑'
                this.dialogVisible = true
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message.error('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该行数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteStudentNoticeTemplate({ID:this.currentRow.ID}).then(res => {
                        if(res.data.Success){
                            this.$message({
                                type:'success',
                                message:res.data.Message
                            })
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
                name:this.filters.name,
                noticeType:this.filters.noticeType,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }        
            getStudentNoticeTemplateListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }              
            }).catch(() => {})
        },
        headClass(){
            return 'text-align:center'
        },
        rowClass(){          
            return 'text-align:center'
        },
        // 编辑表单，内容输入不进去，强制刷新
        change(){
            this.$forceUpdate()
        },
        updateSelect(){
             this.$forceUpdate()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    created(){

    },
    mounted(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>